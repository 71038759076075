import React, {Component, ErrorInfo, ReactNode} from "react";
import Rollbar from "rollbar";
import {Oops} from "./Oops";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  rollbar: Rollbar;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    rollbar: new Rollbar({
      accessToken: process.env.REACT_APP_ROLLBAR_ITEM_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
    })

  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, rollbar: new Rollbar() };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.state.rollbar.error(error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return <Oops/>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;