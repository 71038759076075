import {serviceTypes} from "../types/types";

let BASE_URL = 'http://localhost:8000/';
let BASE_USER_URL = 'https://uploads-dev.grackle.ai';

switch(window.location.hostname) {
  case "localhost":
    BASE_URL =
      process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";
    break;
  default:
    BASE_USER_URL = 'https://uploads.grackle.ai';
    BASE_URL = "https://api.grackle.ai";
}

const api = {
  GET_NOTIFICATIONS: `${BASE_URL}/user/notifications`,
  ME: `${BASE_URL}/me`,
  WORKSPACE: `${BASE_URL}/workspace`,
  HEARTBEAT: `${BASE_URL}/heartbeat`,
  PLANS: `${BASE_URL}/plans`,
  STREAM: (streamId: string) => `${BASE_URL}/streams/${streamId}`,
  SUBSCRIPTION: (subscriptionId: string) => `${BASE_URL}/streams/subscription/${subscriptionId}`,
  USER_TEAMS: `${BASE_URL}/user/teams`,
  GET_USER_STREAM: (streamId: string) => `${BASE_URL}/user/stream/${streamId}`,
  USER_STREAMS: `${BASE_URL}/user/streams`,
  USER_NOTIFICATION: (notificationId: string) => `${BASE_URL}/user/notification/${notificationId}`,
  USER_PROFILE_PIC: `${BASE_URL}/user/picture`,
  GET_ACCOUNT: `${BASE_URL}/user/account`,
  TOGGLE_FOCUS: `${BASE_URL}/user/addRule`,
  USER_SUBSCRIBE: `${BASE_URL}/user/subscribe`,
  USER_SERVICES: (serviceId: string) => `${BASE_URL}/user/service/${serviceId}`,
  ACCEPT_INVITE: `${BASE_URL}/user/invite`,
  CHANGE_WORKSPACE: `${BASE_URL}/user/changeWorkspace`,
  GRACKLE_NEWSFEED: `${BASE_URL}/user/system`,
  GET_ORGS: `${BASE_URL}/org`,
  ORG: (orgId: string) => `${BASE_URL}/org/${orgId}`,
  ORG_STREAM: (orgId: string) => `${BASE_URL}/org/${orgId}/stream`,
  ORG_TEAM: (orgId: string) => `${BASE_URL}/org/${orgId}/team`,
  ORG_PROFILE_PIC: (orgId: string) => `${BASE_URL}/org/${orgId}/picture`,
  TEAM: (teamId: string) => `${BASE_URL}/team/${teamId}`,
  TEAM_MEMBER: (teamId: string) => `${BASE_URL}/team/${teamId}/member`,
  TEAM_STREAMS: (teamId: string) => `${BASE_URL}/team/${teamId}/streams`,
  TEAM_INVITE: (teamId: string) => `${BASE_URL}/team/${teamId}/invitation`,
  TEAM_PROFILE_PIC: (teamId: string) => `${BASE_URL}/team/${teamId}/picture`,
  TEAM_AVAILABLE_STREAMS: (teamId: string) => `${BASE_URL}/team/${teamId}/availableStreams`,
  OAUTH_RESPONSE: (service:serviceTypes) => `${BASE_URL}/oauth/${service}`,
  OAUTH_CONNECT_APP: (service:serviceTypes) => `${BASE_URL}/oauth/app/connect/${service}`,
  GET_SERVICES: `${BASE_URL}/services`,
  GET_SOURCES: `${BASE_URL}/services/sources`,
  STREAMS: `${BASE_URL}/streams`,
  GET_WEBHOOK_TARGETS: (streamId: string) => `${BASE_URL}/streams/targets?streamId=${streamId}`,
  CREATE_WEBHOOK_TARGETS: (streamId: string) => `${BASE_URL}/streams/${streamId}/hook`,
  CREATE_WEBHOOK: (streamId: string) => `${BASE_URL}/streams/${streamId}/createWebhook`,
  ASSIGN_FREE_PLAN: `${BASE_URL}/admin/assignFreePlan`,
  ONBOARD_UPDATE_USER: `${BASE_URL}/onboarding/userInfo`,
  ONBOARD_GET_INVITES: `${BASE_URL}/onboarding/getUserInvites`,
  USER_JOIN_TEAM: `${BASE_URL}/onboarding/joinTeam`,
  USER_CHECK_NAME: `${BASE_URL}/user/checkUsername`,
  ONBOARD_CREATE_ORG: `${BASE_URL}/onboarding/createOrg`,
  COMPLETE_ONBOARDING: `${BASE_URL}/onboarding/completed`,
  FEATURED_SERVICES: `${BASE_URL}/onboarding/featuredServices`,
  POST_TRACKING_EVENT: `${BASE_URL}/tracker`,
  ADMIN: `${BASE_URL}/admin/stats`,
  UPLOAD_FILES: (path: string) => path ? `${BASE_USER_URL}${path}` : '',
  GET_CALLOUT_REDIRECT: `${BASE_URL}/callout`
}

export default api;

export const generateUrl = (source:string, uri: string) => {
  return `${BASE_URL}/hooks/${source}/${uri}`;
}
