import React from "react";
import {createTheme, ThemeOptions, ThemeProvider} from "@mui/material/styles";
import {StylesProvider} from '@mui/styles';

const theme:ThemeOptions = createTheme({
  palette: {
    primary: {
      light: 'rgb(55, 69, 83)',
      main: '#061729',
      dark: 'rgb(4, 16, 28)',
    },
    secondary: {
      main: '#ff3500'
    },
  },
});
const AppThemeProvider:React.FC = ({children}) => {
  return(
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StylesProvider>
  )
}
export default AppThemeProvider;