import React, {useEffect, useState} from "react";
import {changeWorkspace, getWorkspace} from "../api/api";
import {Backdrop, CircularProgress} from "@mui/material";
import {useUser} from "./auth";
import api from "../api/endpoints";
import {team} from "../types/team";
import {workspace} from "../types/workspace";

interface WorkspaceContext {
  workspace: workspace | undefined;
  switchWorkspace: (accountId: string) => Promise<void>;
  accountInfo: {profilePic: string; label: string;}
  selectedAccount: string;
  workspaceTeams: team[];
  isPersonal: () => boolean;
}
const workspaceContext = React.createContext<WorkspaceContext | undefined>(undefined);
workspaceContext.displayName = "Workspace Context";

const useWorkspace = (): WorkspaceContext => {
  const context = React.useContext<WorkspaceContext | undefined>(workspaceContext)
  if(!context){
    throw new Error('Error setting up workspace context');
  }
  return context;
}

const WorkspaceProvider: React.FC = ({children}) => {
  const {loggedInUser, updateUser} = useUser();
  const [workspace, setWorkspace] = useState<workspace>()
  const [workspaceLoading, setWorkspaceLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(loggedInUser ? loggedInUser.settings.workspaceAccountId : "");
  const [accountInfo, setAccountInfo] = useState<{profilePic: string; label: string;}>({profilePic: '', label: ''});
  const [workspaceTeams, setWorkspaceTeams] = useState<team[]>([])

  useEffect(() => {
    if(loggedInUser){
      setSelectedAccount(loggedInUser?.settings.workspaceAccountId);
      getWorkspace().then(res => {
        if(res.success){
          setWorkspace(res.data.workspace);
        }
      });
    }
  }, [loggedInUser])

  useEffect(() => {
    const org = loggedInUser?.organizations.find(org => org.account_id === selectedAccount);
    if(org){
      setAccountInfo({
        profilePic: api.UPLOAD_FILES(org.avatar_url),
        label: org.name
      })
    } else if(loggedInUser) {
      setAccountInfo({
        profilePic: api.UPLOAD_FILES(loggedInUser.profile_pic),
        label: loggedInUser.first_name
      })
    }
  }, [loggedInUser, selectedAccount]);

  useEffect(() => {
    const org = loggedInUser?.organizations.find(org => org.account_id === selectedAccount);
    if(org && loggedInUser){
      const teams = loggedInUser.teams.filter((team) => team.organization_id === org.organization_id);
      if(teams){
        setWorkspaceTeams(teams)
      } else {
        setWorkspaceTeams([]);
      }
    } else {
      setWorkspaceTeams([]);
    }
  }, [loggedInUser, selectedAccount])

  const switchWorkspace = async (accountId: string) => {
    setWorkspaceLoading(true);
    await changeWorkspace(accountId);
    await updateUser();
    setWorkspaceLoading(false);
  }

  const isPersonal = () => {
    return loggedInUser?.account_id === selectedAccount
  }

  return (
    <workspaceContext.Provider value={{
      switchWorkspace,
      accountInfo,
      selectedAccount,
      workspaceTeams,
      isPersonal,
      workspace
    }}>
      {workspaceLoading &&
          <Backdrop
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={workspaceLoading}
          >
              <CircularProgress color="primary" size={60} />
          </Backdrop>
      }
      {!workspaceLoading && children}
    </workspaceContext.Provider>
  )
}

export {WorkspaceProvider, useWorkspace};