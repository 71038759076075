import {alias} from "./alias";
import {activity} from "./activity";
import {user} from "./user";

export interface NewsfeedSearchParams extends Record<string, object | number | string | undefined>{
  page: number;
  view: string;
  priority: string;
  timeline: string;
  senderGroup: string;
  senderType: string[];
  user?: string;
  userObj?: user;
  actor?: string;
  actorObj?: alias;
  stream?: string;
  activity?: string;
  activityObj?: activity
}

export const convertToPayload = (searchParams: NewsfeedSearchParams) => {
  let params: Record<string, string>  = {
    view: searchParams.view,
    priority: searchParams.priority,
    page: searchParams.page.toString(),
    timeline: searchParams.timeline,
    senderGroup: searchParams.senderGroup,
    senderType: searchParams.senderType.join(),
  }

  if(searchParams.user){
    params = {
      ...params,
      user: searchParams.user
    }
  }

  if(searchParams.actor){
    params = {
      ...params,
      actor: searchParams.actor
    }
  }

  if(searchParams.stream){
    params = {
      ...params,
      stream: searchParams.stream
    }
  }

  if(searchParams.activity){
    params = {
      ...params,
      activityId: searchParams.activity
    }
  }

  return params;
}