import React from 'react';
import {useUser} from "./shared/contexts/auth";
import Loading from "./Loading/Loading";
import ErrorBoundary from "./BaseApps/ErrorBoundary";

const AuthenicatedApp = React.lazy(() => import('./BaseApps/AuthenticatedApp'));
const UnauthenicatedApp = React.lazy(() => import('./BaseApps/UnauthenticatedApp'));

function App() {
  let auth = useUser();
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<Loading/>}>
        {auth.isAuthed ? <AuthenicatedApp/> : <UnauthenicatedApp/>}
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default App;
