import React from "react";
import {UserProvider} from "./auth";
import AppThemeProvider from "./theme";
import {SnackbarProvider} from "./snackbar";
import {NotificationsProvider} from "./notifications";
import {WorkspaceProvider} from "./workspace";

const AppProviders: React.FC = ({children}) => {
  return (
    <SnackbarProvider>
      <UserProvider>
        <NotificationsProvider>
          <AppThemeProvider>
            <WorkspaceProvider>
              {children}
            </WorkspaceProvider>
          </AppThemeProvider>
        </NotificationsProvider>
      </UserProvider>
    </SnackbarProvider>
  )
}
export {AppProviders}