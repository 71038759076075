import Cookies from "js-cookie";
import api from "./endpoints";
import {apiResponse} from "./utils";
import {service} from "../types/service";
import {stream} from "../types/stream";
import {notification, notificationUpdateFields} from "../types/notification";
import {loggedInUser, user} from "../types/user";
import {rule} from "../types/rule";
import {settings} from "../types/settings";
import {Member, team, teamParams} from "../types/team";
import {account} from "../types/account";
import {plan} from "../types/plan";
import {integration} from "../types/integration";
import {NewOrgState, organization} from "../types/organization";
import {teamMember} from "../types/teamMember";
import {convertToPayload, NewsfeedSearchParams} from "../types/search";
import {invitation} from "../types/invitation";
import {OnboardingOrgState} from "../../pages/onboarding/views/Onboarding";
import {subscription} from "../types/subscription";
import {workspace} from "../types/workspace";

export const authOrFail = () => {
  const auth = Cookies.get('jwt_token');
  if (!auth) {
    throw new Error('Authentication could not be validated');
  }
  return auth;
}

export const fetchData = async (endpoint: string, method: string, params?: object) => {
  const auth = authOrFail();
  const res = await fetch(endpoint, {
    method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": auth
    },
    body: params ? JSON.stringify(params) : undefined
  })

  const response = await res.json() as apiResponse<any>;

  if(response.message === 'BAD_LOGIN'){
    Cookies.remove('jwt_token');
    Cookies.remove('searchSettings');
  }

  return response;
}

export async function getMe(){
  return await fetchData(api.ME, 'GET') as apiResponse<{user: loggedInUser}>;
}

export async function getWorkspace()
{
  return await fetchData(api.WORKSPACE, 'GET') as apiResponse<{workspace: workspace}>
}

interface getServiceResponse{
  availableServices: service[],
  userServices: Record<string, integration>
}
export async function getServices(){
  const response = await fetchData(api.GET_SERVICES, 'GET') as apiResponse<getServiceResponse>
  return response.data
}

export async function getSources(){
  const response = await fetchData(api.GET_SOURCES, 'GET')
  return response.data
}

export async function getTeamServices(teamId: string){
  const response = await fetchData(api.TEAM_STREAMS(teamId), 'GET')
  return response.data;
}

export async function getTeamAvailableStreams(teamId: string){
  const response = await fetchData(api.TEAM_AVAILABLE_STREAMS(teamId), 'GET');
  return response.data
}

export async function addTeamServices(teamId: string, streams: string[]){
  const response = await fetchData(api.TEAM_STREAMS(teamId), 'POST', {streams: streams})
  return response.data
}

export async function updateSubscription(subscriptionId: string, params: {nickname: string}){
  return await fetchData('PUT', api.SUBSCRIPTION(subscriptionId), {
    params: params
  })
}

export async function removeTeamStream(streamId: string, teamId: string){
  const response = await fetchData(api.TEAM_STREAMS(teamId), 'DELETE', {streamId: streamId})
  return response.data
}

export async function addUserService(serviceId: string, integrationId: string){
  return await fetchData(api.USER_STREAMS, 'POST', {
    serviceId: serviceId,
    integrationId: integrationId
  }) as apiResponse<{stream: stream}>;
}

export async function deleteStream(streamId: string){
  const response = await fetchData(api.STREAMS, 'DELETE', {streamId: streamId})
  return response.data
}

export async function getWorkspaceStreams(){
  const response = await fetchData(api.STREAMS, 'GET') as apiResponse<{streams: stream[]}>;
  return response.data;
}

type RuleArray = Record<string, rule>;
export const getStream = async (streamId: string) => {
  const response = await fetchData(api.STREAM(streamId), 'GET') as apiResponse<{stream: stream, rules: RuleArray, subscription: subscription, isAdmin: boolean}>;
  return response;
}

export const getWebhookTargets = async(streamId: string) => {
  const service = await fetchData(api.GET_WEBHOOK_TARGETS(streamId), 'GET') as apiResponse<{id: string; name: string, avatar: string; type: string; displayType: string}[]>;
  return service.data
}

export const createWebhookTargets = async(streamId: string, target: {id: string; name: string, avatar: string; type: string; displayType: string}) => {
  return await fetchData(api.CREATE_WEBHOOK_TARGETS(streamId), 'POST', {target: target}) as apiResponse<[]>;
}

export const createWebhook = async(streamId: string) => {
  const service = await fetchData(api.CREATE_WEBHOOK(streamId), 'POST') as apiResponse<{stream: stream}>;
  return service.data.stream
}

export async function getNotifications(searchParams: NewsfeedSearchParams) {
  const url = `${api.GET_NOTIFICATIONS}?${( new URLSearchParams( convertToPayload(searchParams) ) ).toString()}`;

  const response = await fetchData(url, 'GET') as apiResponse<{notifications: [notification], total: number, settings: settings}>;
  return response.data;
}

export async function getGrackleNewsfeed(searchParams: NewsfeedSearchParams) {
  const url = `${api.GRACKLE_NEWSFEED}?${( new URLSearchParams( convertToPayload(searchParams) ) ).toString()}`;

  const response = await fetchData(url, 'GET') as apiResponse<{notifications: [notification], total: number, settings: settings}>;
  return response.data;
}

export async function updatePriorityRule(service:string, action:string, priority: string, streamId: string){
  const notifications = await fetchData(api.TOGGLE_FOCUS, 'POST', {
    service: service,
    action: action,
    priority: priority,
    streamId: streamId
  }) as apiResponse<[]>;
  return notifications.data;
}

export async function heartbeat() {
  const response = await fetchData(api.HEARTBEAT, 'GET') as apiResponse<{notification_update_hash: string}>;
  return response.data;
}

export async function getUserTeams(){
  const response = await fetchData(api.USER_TEAMS, 'GET') as apiResponse<{teams: team[]}>;
  return response.data.teams;
}

export async function getUserOrgs(){
  const response = await fetchData(api.GET_ORGS, 'GET') as apiResponse<{organizations: organization[]}>;
  return response.data;
}

export async function getOrganization(orgId: string){
  const response = await fetchData(api.ORG(orgId), 'GET') as apiResponse<{organization: organization, isAdmin: boolean}>;
  return response.data;
}

export async function updateOrganization(orgId: string, params: Partial<organization>){
  const response = await fetchData(api.ORG(orgId), 'PATCH') as apiResponse<{organization: organization}>;
  return response.data;
}

export async function getTeam(teamId: string){
  const response = await fetchData(api.TEAM(teamId), 'GET') as apiResponse<{team: team, isAdmin: boolean}>;
  return response.data;
}

export async function getAccount(){
  const response = await fetchData(api.GET_ACCOUNT, 'GET') as apiResponse<{account: account}>;
  return response.data.account;
}

export async function getUserStream(streamId: string, params: {priority: string, page: string}){
  const url = `${api.GET_USER_STREAM(streamId)}?${( new URLSearchParams( params ) ).toString()}`;

  return await fetchData(url, 'GET') as apiResponse<{notifications: [notification]}>;
}

export async function addMembersToTeam(teamId: string, members: Member[]){
  const response = await fetchData(api.TEAM_MEMBER(teamId), 'POST', {
      members,
    }) as apiResponse<{team: team}>;
  return response.data;
}

export async function updateTeamMember(teamId: string, userId: string, role: string){
  const response = await fetchData(api.TEAM_MEMBER(teamId), 'PUT', {
      userId,
      role
    }) as apiResponse<{teamMember: teamMember}>;
  return response.data;
}

export async function removeTeamMember(teamId: string, userId: string){
  const response = await fetchData(api.TEAM_MEMBER(teamId), 'DELETE', {userId}) as apiResponse<{team: team}>;
  return response.data;
}
export async function acceptInvite(token: string){
  return await fetchData(api.ACCEPT_INVITE, 'POST', {token}) as apiResponse<[]>;
}

export async function changeWorkspace(workspaceAccountId: string){
  return await fetchData(api.CHANGE_WORKSPACE, 'POST', {workspaceAccountId}) as apiResponse<[]>;
}

export async function createOrgTeam(orgId: string, params: Partial<teamParams>) {
  const response = await fetchData(api.ORG_TEAM(orgId), 'POST', {params}) as apiResponse<{organization: organization}>;
  return response.data;
}

export async function createOrgStreams(orgId: string, serviceId: string, integrationId: string) {
  return await fetchData(api.ORG_STREAM(orgId), "POST", {integrationId, serviceId}) as apiResponse<{organization: organization, stream: stream}>;
}

export async function editTeam(teamId: string, params: Partial<teamParams>) {
  return await fetchData(api.TEAM(teamId), 'PUT', {params}) as apiResponse<{team: team}>;
}

export async function cancelTeamInvite(teamId: string, invitationId: string) {
  return await fetchData(api.TEAM_INVITE(teamId), 'DELETE', {invitationId}) as apiResponse<{team: team}>;
}

type Plans = {
  plans: plan[];
}
export async function getPlans() {
  return await fetchData(api.PLANS, 'GET') as apiResponse<Plans>;
}

type Account = {
  account: account;
}
export async function assignFreePlan() {
  return await fetchData(api.ASSIGN_FREE_PLAN, 'POST') as apiResponse<Account>;
}

export async function subscribe(stream_id: string) {
  return await fetchData(api.USER_SUBSCRIBE, 'POST', {stream_id}) as apiResponse<[]>;
}

export async function updateNotification(notification: notification, settings: Partial<notificationUpdateFields>) {
  return await fetchData(api.USER_NOTIFICATION(notification.notification_id), "PATCH", settings) as apiResponse<{notification: notification}>;
}

export async function checkUserService(serviceId: string) {
  const response = await fetchData(api.USER_SERVICES(serviceId), 'GET') as apiResponse<{has_service: boolean, integrations: integration[]}>;
  return response.data;
}

export async function createOrg(orgParams: NewOrgState) {
  const response = await fetchData(api.GET_ORGS, 'POST', {orgParams}) as apiResponse<{organization: organization}>;
  return response.data;
}

export async function postTrackingEvent(event: any) {
  if (!event.user_id) {
    return;
  }
  await fetchData(api.POST_TRACKING_EVENT, 'POST', {event})
}

export async function uploadUserPhoto(file: File) {
  const formData = new FormData();

  formData.append('profilePic', file);

  const auth = authOrFail();
  const res = await fetch(api.USER_PROFILE_PIC, {
    method: "POST",
    headers: {
      Authorization: auth,
    },
    body: formData
  })

  return await res.json() as apiResponse<{updatedUser: user}>;
}
export async function uploadOrgPhoto(orgId: string, file: File) {
  const formData = new FormData();

  formData.append('profilePic', file);

  const auth = authOrFail();
  const res = await fetch(api.ORG_PROFILE_PIC(orgId), {
    method: "POST",
    headers: {
      Authorization: auth,
    },
    body: formData
  })

  return await res.json() as apiResponse<{organization: organization}>;
}
export async function uploadTeamPhoto(teamId: string, file: File) {
  const formData = new FormData();

  formData.append('profilePic', file);

  const auth = authOrFail();
  const res = await fetch(api.TEAM_PROFILE_PIC(teamId), {
    method: "POST",
    headers: {
      Authorization: auth,
    },
    body: formData
  })

  return await res.json() as apiResponse<{team: team}>;
}

export async function getActionCallout(actionId: string){
  const url = `${api.GET_CALLOUT_REDIRECT}?${( new URLSearchParams( {actionId: actionId} ) ).toString()}`;

  return await fetchData(url, 'GET') as apiResponse<{callout_url: string, action_created_at: string}>;
}

//ONBOARDING
export async function onboardingUserInfoSubmit(username: string, invite: string){
  return await fetchData(api.ONBOARD_UPDATE_USER, 'POST', {
      username: username,
      inviteCode: invite
    }) as apiResponse<{}>;
}

export async function checkUsername(username: string){
  const url = `${api.USER_CHECK_NAME}?${( new URLSearchParams( {username: username} ) ).toString()}`;

  return await fetchData(url, 'GET') as apiResponse<{is_taken: boolean}>;
}

export async function getUserInvites(){
  return await fetchData(api.ONBOARD_GET_INVITES, 'GET') as apiResponse<{invitations: invitation[]}>;
}

export async function getFeaturedServices(){
  return await fetchData(api.FEATURED_SERVICES, 'GET') as apiResponse<{services: service[]}>;
}

export async function userJoinTeam(invite_id: string){
  return await fetchData(api.USER_JOIN_TEAM, 'POST', {invitation_id: invite_id,}) as apiResponse<[]>;
}
export async function onboardingCreateOrg(org: OnboardingOrgState) {
  return await fetchData(api.ONBOARD_CREATE_ORG, "POST", {org}) as apiResponse<{ organization: organization }>;
}

export async function completeOnboarding(){
  return await fetchData(api.COMPLETE_ONBOARDING, 'POST') as apiResponse<[]>;
}