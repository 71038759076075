import React from "react";
import {postTrackingEvent} from "../api/api";
import {useUser} from "./auth";

interface contextNotificationsProps {
  pushNotification: (message: string, body: string, cb: () => void) => void;
}
const notificationsContext = React.createContext<contextNotificationsProps|undefined>(undefined)
notificationsContext.displayName = 'Notifications Context';

const useNotifications = () =>  {
  const context = React.useContext<contextNotificationsProps|undefined>(notificationsContext)
  if(!context){
    throw new Error('Notifications context not defined');
  }
  return context;
}

const NotificationsProvider: React.FC = ({children}) => {
  let isEnabled = false;
  let {loggedInUser} = useUser();

  if ("Notification" in window) {
    Notification.requestPermission().then((result) => {
      isEnabled = result === 'granted';
    });
  }

  const pushNotification = (message: string, body: string, cb: () => void) => {
    const callback = () => {
      postTrackingEvent({
        name: 'UserImpressesAlert',
        user_id: loggedInUser?.user_id,
      });
      window.focus();
      cb();
    }
    if(isEnabled){
      try{
        const notification = new Notification(message, {
          icon: 'grackle-logo.png',
          body: body
        });
        if(notification){
          notification.onclick = callback;
        }
        setTimeout(() => {notification.close()}, 5000);
      } catch (e) {
        return false;
      }
    }
  }

  return(
    <notificationsContext.Provider value={{pushNotification: pushNotification}}>
      {children}
    </notificationsContext.Provider>
  )
}

export {NotificationsProvider, useNotifications}