import {Button, Container, Grid, Stack, Typography} from "@mui/material";
import oops from '../shared/images/oops.png';

export const Oops = () => {
  return(
    <Container maxWidth={"lg"} style={{height: '100vh'}}>
      <Grid container justifyContent={'center'} alignItems={'center'} style={{height: '100vh'}} spacing={2}>
        <Grid item xs={6}>
          <Stack spacing={3}>
            <Typography variant={"h3"}>Sorry :( Something unexpected happened.</Typography>
            <Typography variant={"h5"}>Our team is working hard to get it back to normal as soon as possible.</Typography>
            <div>
              <Button variant={'contained'} href={'/'}>Return Home</Button>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <img src={oops} alt='oops' width={400}/>
        </Grid>
      </Grid>
    </Container>
  )
}