import React, {useState} from "react";
import {IconButton, Snackbar} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface snackbarContextProps {
  createMessage: (message: string) => void;
  closeSnackbar: () => void;
}

const snackbarContext = React.createContext<snackbarContextProps | undefined>(undefined);
snackbarContext.displayName = 'Snackbar Context';

const useSnackbar = ():snackbarContextProps => {
  const context = React.useContext<snackbarContextProps | undefined>(snackbarContext)
  if(!context){
    throw new Error('Snackbar context undefined');
  }
  return context;
}

const SnackbarProvider:React.FC = ({children}) => {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  const createMessage = (message: string) => {
    setMessage(message)
    setOpen(true);
  }
  const closeSnackbar = () =>{
    setOpen(false);
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <snackbarContext.Provider value={{createMessage, closeSnackbar}}>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        open={open}
        onClose={closeSnackbar}
        onClick={closeSnackbar}
        autoHideDuration={5000}
        message={message}
        action={action}
      />
      {children}
    </snackbarContext.Provider>
  )
}

export {SnackbarProvider, useSnackbar};