import React, {useEffect, useState} from "react";
import Cookies from 'js-cookie'
import {loggedInUser} from "../types/user";
import {getMe} from "../api/api";
import {useSnackbar} from "./snackbar";

interface LoginData {
    user: loggedInUser;
    jwt: string;
}

interface UserContext {
    isAuthed: boolean;
    login: (data:LoginData) => void;
    logout: () => void;
    loggedInUser: loggedInUser | undefined;
    updateUser: () => Promise<void>;
}

const userContext = React.createContext<UserContext | undefined>(undefined);
userContext.displayName = "Authentication Context";

const useUser = ():UserContext => {
    const context = React.useContext<UserContext | undefined>(userContext)
    if(!context){
        throw new Error('Error setting up auth context');
    }
    return context;
}

const UserProvider:React.FC = ({ children}) => {
    const [isAuthed, setIsAuthed] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState<loggedInUser>();

    const {createMessage} = useSnackbar();

    useEffect(() => {
        const jwtToken = Cookies.get('jwt_token');
        setIsAuthed(!!jwtToken);
    }, []);

    useEffect(() => {
        if(isAuthed){
            getMe().then((response) => {
                if(response.success){
                    const {user} = response.data
                    setLoggedInUser({
                        ...user,
                        settings: {
                            ...user.settings
                        }
                    });
                } else {
                    logout();
                }
            })
        } else {
            setLoggedInUser(undefined);
        }
    }, [isAuthed]);

    function login(data: LoginData){
        if(data.jwt && data.user)
        {
            Cookies.set('jwt_token', data.jwt);
            setIsAuthed(true);
            setLoggedInUser({
                ...data.user,
                settings: {
                    ...data.user.settings
                }
            });
        } else {
            setLoggedInUser(undefined);
            console.error('Error logging into Grackle');
            createMessage('There is was a system error logging into Grackle');
        }
    }

    function logout(){
        Cookies.remove('jwt_token');
        Cookies.remove('searchSettings');
        setLoggedInUser(undefined);
        setIsAuthed(false);
    }

    function updateUser() {
        return getMe().then((response) => {
            if(response.success)
            {
                const {user} = response.data;
                setLoggedInUser({
                    ...user,
                    settings: {
                        ...user.settings
                    }
                });
            } else {
                logout();
            }
        })
    }
    return (
        <userContext.Provider value={
            {
                isAuthed,
                login,
                logout,
                loggedInUser,
                updateUser,
            }
        }>
            {children}
        </userContext.Provider>
    );
}

export {UserProvider, useUser};